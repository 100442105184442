.btn-blue {
  font-weight: 700;
  color: #ffffff;
  background-color: $fittly-blue;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  box-shadow: 10px 10px 15px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
  color: #ffffff;
  background-color: $fittly-orange;
  border: 0;
}

.btn-gray {
  font-weight: 700;
  color: $fittly-provider;
  background-color: $fittly-mid-gray;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  box-shadow: 10px 10px 35px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
  color: #ffffff;
  background-color: $fittly-orange;
  border: 0;
}

.btn-white {
  font-weight: 700;
  color: $fittly-blue;
  background-color: transparent;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  cursor: default;
  //color: #ffffff;
  //background-color: $fittly-orange;
  //border: 0;
}

.btn-outline-blue {
  font-weight: 700;
  color: $fittly-blue;
  background-color: #fff;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;

  letter-spacing: 0.1px;
  border-color: $fittly-blue;
  border-width: 1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active {
  color: #ffffff;
  background-color: $fittly-blue;
  border-color: $fittly-blue;
}

.btn-stripe {
  font-weight: 700;
  color: #ffffff;
  background-color: $fittly-dark-stripe;
  border: 0;
  font-size: $fs18 !important;
  border-radius: 50px;
  padding: 10px 32px;
  box-shadow: 10px 10px 35px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;
  justify-content: center;
  align-items: center;
  display: flex;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }

  img {
    padding-left: 7px;
    height: 18px;
    margin-top: 2px;
    width: auto;
  }
}

.btn-stripe:hover,
.btn-stripe:focus,
.btn-stripe:active,
.btn-stripe.active {
  color: #ffffff;
  background-color: $fittly-dark-stripe;
  border: 0;
}
