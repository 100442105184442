.boxDueDate {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  padding: 30px;
  background-image: url("../assets/bg-payment.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.billingInfo {
    background-image: none;
  }

  h4 {
    font-family: "Manrope", sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }

  .date {
    background: rgba(208, 222, 223, 0.16);
    border-radius: 17px;
    width: 100%;
    margin: 30px 0 30px 0;

    @media only screen and (max-width: 767px) {
      padding-bottom: 15px;
    }

    h5 {
      font-family: "Manrope", sans-serif !important;
      font-weight: bold;
      font-size: 64px;

      color: $fittly-blue;

      span {
        font-family: "Manrope", sans-serif !important;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $fittly-blue;
        margin-left: -5px;
      }
    }

    p.dueDateTitle {
      font-family: "BrandonTextRegular";
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 0;
    }

    p.dueDate {
      font-family: "BrandonTextRegular";
      font-size: 18px;
      line-height: 20px;
      color: $fittly-text-gray;
      margin-bottom: 0;
    }
  }

  p.billingDate {
    font-family: "Manrope", sans-serif !important;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
  }
}

.card {
  background: #ffffff;
  border: 1px solid $fittly-blue;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 30px;

  .logo {
    width: 100%;
    text-align: right;
  }

  .logo img {
    width: 50px;
    height: auto;
  }

  .number {
    width: 100%;
    font-family: "Manrope", sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 3px;
    text-align: center;
    padding: 30px 0 30px 0;
    margin-bottom: 0;
  }

  .format,
  .validDate {
    font-family: "Manrope", sans-serif !important;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0;
  }
}
.titularInfo {
  padding-left: 40px;
  padding-right: 40px;
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  h4 {
    font-family: "Manrope", sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }

  p {
    font-family: "Manrope", sans-serif !important;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0;
  }
}

.invoicesTable {
  padding: 50px 0 50px 0;
  h4 {
    font-family: "Manrope", sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 30px;
  }
  .listInvoices {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    padding: 40px 60px;
    font-family: "Manrope", sans-serif !important;

    @media only screen and (max-width: 767px) {
      padding: 40px 20px;
    }

    .download_pdf {
      font-family: "Manrope", sans-serif !important;
      font-weight: normal;
      font-size: 15px !important;
      line-height: 20px;
      color: $fittly-provider;
    }

    table {
      thead {
        font-family: "Manrope", sans-serif !important;
        font-weight: normal !important;
        font-size: 12px;
        line-height: 20px;
        color: #d0dedf;

        th {
          &:last-child {
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          border-color: transparent;

          td {
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }

    .invoice .text {
      font-family: "Manrope", sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 0;
      padding-top: 10px;

      &.bold {
        font-weight: bold;
      }
    }

    .invoice .text.labelGreen {
      color: $fittly-green;
    }

    .invoice .text.labelRed {
      color: $fittly-red;
    }
  }
}
