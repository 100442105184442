.navOptions {
  .nav-tabs {
    border-width: 2px !important;
    border-bottom-color: $fittly-light-teal !important;
    font-family: "Manrope", sans-serif !important;
  }
  .nav-link {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom-color: transparent;
    border-bottom-width: 2px !important;
    color: $fittly-light-teal !important;
    font-size: $fs16;
    padding: 15px 30px;
    margin-right: 30px;
    font-family: "Manrope", sans-serif !important;
  }
  .nav-link.active {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom-color: $fittly-darkblue !important;
    border-bottom-width: 2px !important;
    color: $fittly-darkblue !important;
    font-weight: bold;
  }
  .nav-link:hover {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom-color: $fittly-darkblue !important;
    border-bottom-width: 2px !important;
    color: $fittly-darkblue !important;
  }

  @media only screen and (max-width: 767px) {
    .nav-item {
      width: 100%;
      margin-top: 20px;

      .nav-link {
        width: 100%;
      }
    }
  }
}
