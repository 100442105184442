@import "~bootstrap/scss/bootstrap";
@import "./src/normalizer";
@import "./variables/fonts";
@import "./variables/colors";
@import "./global";

//GENERALS
@import "./buttons";
@import "./header";
@import "./navOptions";

//SECTIONS
@import "./login";
@import "./yourPlan";
@import "./upgradePlan";
@import "./billing";
