.header {
  .container {
    align-items: center;

    .row {
      .col {
        display: flex;
        align-items: center;

        &.pull-end {
          justify-content: flex-end;
        }

        img {
          display: flex;
        }
      }
    }
  }
  .tab-navs {
    margin-right: 30px;

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
      margin-right: 0px;

      &.nav {
        .nav-item {
          width: 100% !important;
        }
      }
    }

    .tab {
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      color: $fittly-light-teal !important;
      padding-top: 0;
      align-items: center;
      display: flex;

      img {
        display: flex;
        visibility: hidden;
        height: 16px;
        width: 32px;
        margin-bottom: 5px;

        @media only screen and (max-width: 767px) {
          display: none !important;
        }
      }

      &.active {
        color: $fittly-provider !important;
        font-weight: bold;

        img {
          visibility: visible;
        }
      }
    }
  }
}
