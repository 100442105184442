.formLogin {
  //border: 1px solid $fittly-blue;
  border-radius: 10px;
  padding: 30px;
  background-color: $fittly-white;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);

  h4{
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    padding: 40px 0;
  }

  .grouped-input{

    margin: 20px 30px;

    label{
      color: $fittly-blue;
      font-weight: bold;
      margin-bottom: 5px;
    }

    input{
      border-color: $fittly-gray !important;
    }

  }


}
