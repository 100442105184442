.headerPlan {
  h3 {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    color: #000000;
    span {
      font-weight: bold;
      color: $fittly-blue;
    }
  }
  h6 {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
  }
}

.textPrices {
  font-family: "Manrope", sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: $fs20;
  line-height: $fs20;
  color: $fittly-blue;
}

.descripPrices {
  font-family: "Manrope", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: $fs16 !important;
  line-height: $fs20 !important;
  color: $fittly-light-teal !important;
}

.planInfo {
  padding-top: 40px;

  .containerInfo {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    padding: 40px;
  }

  .label {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;

    color: #000000;
    @media only screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
  }
  .progress {
    border-radius: 10px;
    height: 20px;
    margin-top: 1px;
  }

  .progress-bar {
    background-color: $fittly-blue;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .totalJobsNow {
    width: 100px;
    height: 100px;
    background-image: url("../assets/union.png");
    background-position: center center;
    background-repeat: no-repeat;
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 24px;
    text-align: center;
    color: #0d6e76;
    padding-top: 33px;
    padding-left: 5px;
  }
}
