body {
  font-family: "BrandonTextRegular";
}
.main_bg {
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;

  @media only screen and (max-width: 767px) {
    background-position: center center;
  }
}

.noSelectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
