// COLORS
$fittly-mid-gray: #e5e5e5;
$fittly-orange: #eba14a;
$fittly-red: #d17f5c;
$fittly-blue: #31aeb8;
$fittly-provider: #0d6e76;
$fittly-darkblue: #06747c;
$fittly-beige: #f2efea;
$fittly-sand: #ded5c8;
$fittly-light-teal: #81a6a9;
$fittly-dark-teal: #213638;
$fittly-text-gray: #18191f;
$fittly-neutral-black: #131d1d;
$fittly-light-grey: #e4eced;
$fittly-white: #fff;
$fittly-green: #6bbd78;
$fittly-red: #fc9292;
$fittly-stripe: #5e66e5;
$fittly-dark-stripe: #1b2296;
$fittly-text-gray: #717171;
$fittly-gray: #efefef;
$fittly-light: #f9fcfc;
$fittly-items: #707e80;

// FONT SIZES
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs24: 24px;
$fs32: 32px;
$fs40: 40px;
$fs44: 44px;
$fs48: 48px;
$fs64: 64px;
